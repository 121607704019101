import { SWRConfig } from 'swr'

import { AppRoutes } from './Routes'
import { FullLoader } from 'components/FullLoader'

import { fetcher } from 'utils/axios'
import { useAuthStore } from 'store/auth.store'

const swrConfig = {
  // refreshInterval: 3000,
  loadingTimeout: 10000,
  shouldRetryOnError: true,
  errorRetryInterval: 3000,
  errorRetryCount: 2,
  fetcher,
}



export function App() {
  const loading = useAuthStore((state) => state.loading && !state.uid)
  if (loading) return <FullLoader />

  return (
    <SWRConfig value={swrConfig}>
        <AppRoutes />
    </SWRConfig>
  )
}
