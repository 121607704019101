import { Flex, Typography } from 'antd'
import { Helmet } from 'react-helmet'

interface Props {
  title: string
}

export function Page(props: PropsWithChildren<Props>) {
  const { title } = props
  const placeholderTitle = <span style={{ opacity: 0 }}>Title</span>

  return (
    <Flex gap={10} vertical style={blockStyle}>
      <Helmet>
        <title>Equility - {title}</title>
      </Helmet>
      <Typography.Title level={2} style={headerStyle}>
        {title || placeholderTitle}
      </Typography.Title>
      {props.children}
    </Flex>
  )
}

const blockStyle: React.CSSProperties = {
  paddingLeft: 20,
  paddingRight: 20,
}

const headerStyle: React.CSSProperties = {
  margin: 0,
  marginTop: 10,
  fontWeight: 500,
  opacity: 0.5,
}
