declare global {
  interface Window {
    heap: {
      load: (envId: string, config?: any) => void
      [key: string]: any
    }
    heapReadyCb: Array<{
      name: string
      fn: () => void
    }>
  }
}

export const initHeap = () => {
  const envId = process.env.REACT_APP_HEAP_ID || '3221802347' // production heap id

  window.heapReadyCb = window.heapReadyCb || []
  window.heap = window.heap || []

  window.heap.load = function (e: string, t: any) {
    window.heap.envId = e
    window.heap.clientConfig = t = t || {}
    window.heap.clientConfig.shouldFetchServerConfig = false

    const a = document.createElement('script')
    a.type = 'text/javascript'
    a.async = true
    a.src = `https://cdn.us.heap-api.com/config/${e}/heap_config.js`

    const r = document.getElementsByTagName('script')[0]
    r.parentNode?.insertBefore(a, r)

    const n = [
      'init',
      'startTracking',
      'stopTracking',
      'track',
      'resetIdentity',
      'identify',
      'getSessionId',
      'getUserId',
      'getIdentity',
      'addUserProperties',
      'addEventProperties',
      'removeEventProperty',
      'clearEventProperties',
      'addAccountProperties',
      'addAdapter',
      'addTransformer',
      'addTransformerFn',
      'onReady',
      'addPageviewProperties',
      'removePageviewProperty',
      'clearPageviewProperties',
      'trackPageview',
    ]

    const i = function (e: string) {
      return function () {
        const t = Array.prototype.slice.call(arguments, 0)
        window.heapReadyCb.push({
          name: e,
          fn: function () {
            window.heap[e] && window.heap[e].apply(window.heap, t)
          },
        })
      }
    }

    for (let p = 0; p < n.length; p++) {
      window.heap[n[p]] = i(n[p])
    }
  }

  window.heap.load(envId)
}
