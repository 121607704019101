import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, DatePicker, Flex, Result, Select, Typography } from 'antd'

import { ReportTable } from './components/ReportTable'
import { FullLoader } from 'components/FullLoader'

import { useCompany } from 'hooks/useCompany'
import { parseDate, periodStart, prevMonthStart, prevPeriodStart } from 'utils/dates'
import { ConnectionType } from 'types/company.types'
import type { IReportParams } from 'types/report.type'
import type { Dayjs } from 'dayjs'

const { Text } = Typography

const defaultDate = prevMonthStart()
const defautlPeriod = 'month'

const initialParams: IReportParams = {
  companyId: 0,
  method: 'Cash',
  from: defaultDate,
  to: prevPeriodStart(defaultDate, defautlPeriod),
  type: 'income',
  date_macro: defautlPeriod,
}

export function FinancialTab() {
  const { company } = useCompany()
  const navigate = useNavigate()

  const accountingMethod = (company?.settings?.accountingMethod as 'Cash' | 'Accrual') || 'Cash'

  const qbo = (company?.connections || []).find((i) => i.type.id === ConnectionType.QBO)
  const isConnected = qbo?.status === 1

  const [expandLevel, setExpandLevel] = useState(0)
  const [params, setParams] = useState<IReportParams>({ ...initialParams, method: accountingMethod })

  useEffect(() => {
    if (!company?.id) return
    const method = (company?.settings?.accountingMethod as 'Cash' | 'Accrual') || 'Cash'
    setParams({ ...params, companyId: company.id, method })
  }, [company])

  const onReconnect = () => navigate('./settings/accounting', { relative: 'path' })

  const onChangeFrom = (date: Dayjs) => {
    date ? setParams({ ...params, from: periodStart(date) }) : setParams({ ...params, from: '' })
  }

  const onChangeTo = (date: Dayjs) => {
    date ? setParams({ ...params, to: periodStart(date) }) : setParams({ ...params, to: '' })
  }

  const onMacroChange = (date_macro: any) => {
    setParams({ ...params, date_macro, to: params.from ? prevPeriodStart(params.from, date_macro) : '' })
  }

  if (!company?.id) return <FullLoader />
  if (!isConnected)
    return (
      <Result
        status="warning"
        title="QuickBooks Online is not connected"
        subTitle="Please connect to QuickBooks Online to view financial reports."
        extra={
          <Button type="primary" onClick={onReconnect}>
            Connect to QuickBooks Online
          </Button>
        }
      />
    )

  return (
    <Flex gap={10} vertical>
      <Flex gap={10} align="center">
        <Text>View</Text>
        <Select
          style={{ width: 220 }}
          defaultValue={accountTypes[0].value}
          options={accountTypes}
          onChange={(type: any) => setParams({ ...params, type })}
        />
        <Text>report basis</Text>
        <Select
          style={{ width: 160 }}
          defaultValue={accountingMethod}
          options={accountingMethods}
          onChange={(method: any) => setParams({ ...params, method })}
        />
        <Text>showing</Text>
        <Select style={{ width: 160 }} defaultValue={0} options={showingLevels} onChange={(v) => setExpandLevel(v)} />
      </Flex>
      <Flex gap={10} align="center">
        <Text>For the</Text>
        <Select
          style={{ width: 100 }}
          defaultValue={serializedColumnByTypes[0].value}
          options={serializedColumnByTypes}
          onChange={onMacroChange}
        />
        <Text>of</Text>
        <DatePicker
          picker={params.date_macro}
          value={params.from ? parseDate(params.from) : undefined}
          onChange={onChangeFrom}
        />
        <Text>compared with</Text>
        <DatePicker
          picker={params.date_macro}
          value={params.to ? parseDate(params.to) : undefined}
          onChange={onChangeTo}
        />
      </Flex>
      <ReportTable params={params} expand={expandLevel} />
    </Flex>
  )
}

export const accountingMethods = [
  { label: 'Cash-Basis', value: 'Cash' },
  { label: 'Accrual-Basis', value: 'Accrual' },
]

const accountTypes = [
  { label: 'Profit & Loss Summary', value: 'income' },
  { label: 'Balance Sheet Summary', value: 'balance' },
  { label: 'Cash Flow Summary', value: 'cashflow' },
]

const showingLevels = [
  { label: 'Summary', value: 0 },
  { label: 'Level 1', value: 1 },
  { label: 'Level 2', value: 2 },
  { label: 'Level 3', value: 10 },
]

export const serializedColumnByTypes = [
  { label: 'Month', value: 'month' },
  { label: 'Quarter', value: 'quarter' },
  { label: 'Year', value: 'year' },
]
