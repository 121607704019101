import { Button, Flex, Result } from 'antd'

interface Props {
  onDone: () => void
}

export function DoneStep(props: Props) {
  const { onDone } = props

  return (
    <Flex vertical>
      <Result
        status="success"
        title="That’s it, you’ve done it!"
        subTitle="Thanks for the help! You’re now ready to use Equility!"
        extra={[
          <Button type="primary" key="console" onClick={onDone}>
            Let's Go!
          </Button>,
          // <Button key="buy">Add one more company</Button>,
        ]}
      />
    </Flex>
  )
}
