import { useEffect, useState } from 'react'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import { Button, Divider, Flex, Input, Modal, Result, notification } from 'antd'
import { Tag, Typography, Table, type TableColumnsType } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, CommentOutlined } from '@ant-design/icons'
import { ShareAltOutlined } from '@ant-design/icons'

import { ColorNumber } from 'components/ColorNumber'
import { FullLoader } from 'components/FullLoader'
import { getMethodLabel } from 'components/SelectMethod'
import { getEntityLabel } from 'components/SelectEntity'
import { Details } from './components/Details'
import AnimatedProgress from 'components/AnimatedProgress'

import { useReview } from 'hooks/useReview'
import { useCompany } from 'hooks/useCompany'
import { useUser } from 'hooks/useUser'
import { useRole } from 'hooks/useRole'
import { useAccounts } from 'hooks/useAccounts'
import { formatDate } from 'utils/dates'
import { getErrorMsg } from 'utils/geterror'
import { isPluginInstalled, runReviewSync } from 'utils/plugin'
import { RoleType } from 'types/user.types'
import { ConnectionType } from 'types/company.types'
import type { IBaseReportRow } from 'types/report.type'
import type { IReview } from 'types/review.types'

const { Text } = Typography

export function ReviewResult() {
  const navigate = useNavigate()

  const { isAllowed } = useRole(RoleType.Member)
  const { state } = useLocation()
  const { company } = useCompany()
  const { user } = useUser()
  const { accounts } = useAccounts()

  const [changes, setChanges] = useState<IReview>()
  const [expanded1, setExpanded1] = useState<string[]>([])
  const [expanded2, setExpanded2] = useState<string[]>([])

  const isPlugin = isPluginInstalled()
  const qbo = (company?.connections || []).find((i) => i.type.id === ConnectionType.QBO)

  const match = useMatch('company/:companyId/review/result/:id')
  const id = match?.params?.id || changes?.id

  const params = { id, companyId: company?.id, ...state }
  const review = useReview(params)

  const data = changes || review.data

  useEffect(() => {
    if (!company) return
    if (!id && !!state?.from && !!state?.to) {
      review
        .create(state)
        .then(setChanges)
        .catch((err) => notification.error(getErrorMsg(err)))
    }
  }, [id, state, company])

  if (review?.error) return <ErrorMessage />
  if (!data?.data) return <FullLoader />

  const balanceSheet: IBaseReportRow[] = data?.data.balanceSheet?.report || []
  const incomeStatement: IBaseReportRow[] = data?.data.incomeStatement?.report || []

  const allRecords = [...balanceSheet, ...incomeStatement]
  const failedallRecords = allRecords.filter((record) => !record.meta?.check?.result)
  const percent = ((allRecords.length - failedallRecords.length) / allRecords.length) * 100

  const onComment = (record: IBaseReportRow) => {
    Modal.info({
      title: 'Comment',
      content: (
        <Flex vertical gap={10}>
          <Input.TextArea
            disabled={!isAllowed}
            allowClear
            rows={4}
            defaultValue={record.meta?.comment?.text}
            onChange={(e) => {
              const comment = e.target.value
                ? {
                    text: e.target.value,
                    user: [user?.firstName, user?.lastName].join(' '),
                    date: new Date().toISOString(),
                  }
                : null
              const meta = { ...record.meta, comment }
              const newRecord = { ...record, meta }
              onRecordChange(newRecord)
            }}
          />
          {record.meta?.comment && (
            <Text>
              Note by {record.meta?.comment?.user} on {formatDate(record.meta?.comment?.date)}
            </Text>
          )}
        </Flex>
      ),
      width: 600,
      closable: true,
    })
  }

  const onRecordChange = (record: IBaseReportRow) => {
    const prev = changes || review.data
    if (!prev) return

    const reportType = record.key.split('-')[0]
    const rIndex = reportType === 'balancesheet' ? 'balanceSheet' : 'incomeStatement'
    const arr = prev.data[rIndex].report

    const isResult = (r: IBaseReportRow) => r.meta?.check?.result || r.meta?.override?.result
    function deepMap(v: IBaseReportRow): IBaseReportRow {
      if (v.key === record.key) return record
      if (v.type === 'Section' && v.rows) {
        const rows = v.rows?.map(deepMap)
        const check = { ...v.meta.check, result: v.meta.override?.result || rows.every(isResult) }
        const meta = { ...v.meta, check }
        return { ...v, rows, meta }
      }
      return v
    }

    const newReport = [...arr].map(deepMap)
    const newData = { ...prev.data[rIndex], report: newReport }
    setChanges({ ...prev, data: { ...prev.data, [rIndex]: newData } })
  }

  const onDetails = (record: any) => {
    let title = record.meta?.check?.result ? 'Passed' : 'Failed'
    let action: 'success' | 'warning' = record.meta?.check?.result ? 'success' : 'warning'

    if (record.meta?.override?.result) {
      title = 'Overriden'
      action = 'success'
    }

    const account = accounts.find((v) => v.name === record.name)
    const parrent =
      record.key.search('-summary') > 0
        ? treeFind(allRecords, record.key.replace('-summary', ''))
        : treeFind(allRecords, record.key.split('-').slice(0, -1).join('-'))

    const modal = Modal[action]({
      title: title,
      content: (
        <Details
          update={(_) => modal.update(_)}
          record={record}
          onChange={(_, isOveridden) => {
            onRecordChange(_)
            if (isOveridden) onComment(_)
          }}
          onCancel={() => modal.destroy()}
          from={state.from}
          to={state.to}
          method={state.method}
          account={account}
          parrent={parrent}
        />
      ),
      width: 800,
      closable: true,
      footer: null,
    })
  }

  const onSave = () => {
    if (!isAllowed) return notification.error({ message: 'Error', description: 'You are not allowed to save' })
    if (!changes) return alert('No changes')
    review
      .update({ ...changes, rating: percent })
      .then(() => notification.success({ message: 'Success', description: 'Review saved' }))
      .catch((err) => notification.error(getErrorMsg(err)))
  }

  const onRun = () => {
    if (!company) return
    if (isPlugin) {
      const connectionInfo = qbo?.connectionInfo || '{}'
      runReviewSync({
        from: state.from,
        to: state.to,
        companyId: company.id,
        realmId: connectionInfo.realm_id,
        method: state.method,
      })
    } else {
      const pathname = `/company/${company.id}/review`
      navigate(pathname)
    }
  }

  columns[1].render = (_, record) => {
    const value = record.meta?.check?.rules?.filter((v: any) => !v.formula).length || 0
    const children = flatten(record.meta?.check?.children || []).flatMap((v: any) => v.rules)
    const childrenLength = children.filter((v: any) => !v.formula).length

    if (record.type === 'Section') {
      if (expanded1.includes(record.key)) return value || ''
      if (expanded2.includes(record.key)) return value || ''
      return value + childrenLength || ''
    }
    if (record.type === 'Summary') {
      return value || ''
    }
    return value || ''
  }

  columns[2].render = (_, record) => {
    const value = record.meta?.check?.rules?.filter((v: any) => !!v.formula).length || 0
    const children = flatten(record.meta?.check?.children || []).flatMap((v: any) => v.rules)
    const childrenLength = children.filter((v: any) => !!v.formula).length

    if (record.type === 'Section') {
      if (expanded1.includes(record.key)) return value || ''
      if (expanded2.includes(record.key)) return value || ''
      return value + childrenLength || ''
    }
    if (record.type === 'Summary') {
      return value || ''
    }
    return value || ''
  }

  columns[3].render = (value: any, record) => {
    if (record.type === 'Section') {
      if (expanded1.includes(record.key)) return value && <ColorNumber amount={value} />
      if (expanded2.includes(record.key)) return value && <ColorNumber amount={value} />
      const summary: any = record.rows?.find((v: any) => v.type === 'Summary')
      return (
        <b>
          <ColorNumber amount={summary?.value} />
        </b>
      )
    }
    if (record.type === 'Summary')
      return (
        <b>
          <ColorNumber amount={value} />
        </b>
      )
    return <ColorNumber amount={value} />
  }

  columns[5].render = (_, record) => {
    return (
      <Flex gap={10}>
        <Button
          type="text"
          size="small"
          style={detailsStyle}
          onClick={() => onComment(record)}
          icon={<CommentOutlined />}
          danger={record.meta?.comment}
        />
        <Button size="small" style={detailsStyle} onClick={() => onDetails(record)}>
          Details
        </Button>
      </Flex>
    )
  }

  const roudedPercent = +percent.toFixed(2)
  const userName = [state.user?.firstName, state.user?.lastName].filter(Boolean).join(' ')

  return (
    <Flex vertical>
      <Divider orientation="left">Equility Company</Divider>
      <Flex justify="space-between" align="center">
        <Flex gap={40}>
          <AnimatedProgress percent={roudedPercent} strokeWidth={10} />
          <Flex vertical gap={10}>
            <Flex gap={10}>
              <Text strong>Report date:</Text>
              <Text>{formatDate(state.createdAt || new Date(), 'll')}</Text>
            </Flex>
            <Flex gap={10}>
              <Text strong>Date range:</Text>
              <Text>
                {formatDate(state.from, 'll')} - {formatDate(state.to, 'll')}
              </Text>
            </Flex>
            <Flex gap={10}>
              <Text strong>Accounting method:</Text>
              <Text>{getMethodLabel(state.method)}</Text>
            </Flex>
            <Flex gap={10}>
              <Text strong>Bussiness entity:</Text>
              <Text>{getEntityLabel(company?.entity as any)}</Text>
            </Flex>
            {userName && (
              <Flex gap={10}>
                <Text strong>Completed By:</Text>
                <Text>{userName || ''}</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex gap={10} vertical>
          <Button
            type="primary"
            onClick={onSave}
            disabled={data.finished || !changes || !isAllowed}
            loading={review.isLoading}
          >
            Save
          </Button>
          <Button onClick={onRun} disabled={data.finished || !isAllowed} loading={review.isLoading}>
            Rerun
          </Button>
          {/* <Button disabled>Download PDF</Button> */}
          <Button disabled icon={<ShareAltOutlined />}>
            Share
          </Button>
        </Flex>
      </Flex>
      <Divider orientation="left">Balance Sheets</Divider>
      <Table
        columns={columns}
        dataSource={balanceSheet}
        pagination={false}
        loading={review.isLoading}
        rowClassName={(record: any) => record.type || ''}
        expandable={{
          childrenColumnName: 'rows',
          onExpandedRowsChange: (r) => setExpanded1(r as string[]),
        }}
      />
      <Divider orientation="left">Income Statement</Divider>
      <Table
        columns={columns}
        dataSource={incomeStatement}
        pagination={false}
        loading={review.isLoading}
        rowClassName={(record: any) => record.type || ''}
        expandable={{
          childrenColumnName: 'rows',
          onExpandedRowsChange: (r) => setExpanded2(r as string[]),
        }}
      />
    </Flex>
  )
}

const flatten = (data: any) => data.flatMap(({ children, ...item }: any) => [item, ...flatten(children)])

const columns: TableColumnsType<IBaseReportRow> = [
  {
    title: 'Account',
    dataIndex: 'name',
    render: (value, record) => {
      if (record.type === 'Summary') return <b>{value}</b>
      return value
    },
  },
  {
    title: 'Default rules',
    dataIndex: 'value',
    width: 180,
    render: (_, record) => {
      return record.meta?.check?.rules?.filter((r: any) => !r.formula).length || ''
    },
  },
  {
    title: 'Custom rules',
    dataIndex: 'value',
    width: 180,
    render: (_, record) => {
      return record.meta?.check?.rules?.filter((r: any) => !!r.formula).length || ''
    },
  },
  {
    title: 'Total',
    dataIndex: 'value',
    width: 180,
  },
  {
    title: 'Result',
    dataIndex: 'result',
    width: 120,
    render: (_, record) => {
      if (!record.meta?.check) return '-'
      return record.meta?.check?.result ? <PassTag /> : <FailTag />
    },
  },
  {
    title: '',
    dataIndex: 'id',
    width: 100,
  },
]

const PassTag = () => (
  <Tag icon={<CheckCircleOutlined />} color="green">
    Pass
  </Tag>
)
const FailTag = () => (
  <Tag icon={<CloseCircleOutlined />} color="error">
    Fail
  </Tag>
)

const detailsStyle: React.CSSProperties = {
  marginTop: -5,
  marginBottom: -5,
}

function treeFind(arr: IBaseReportRow[] = [], key: string): IBaseReportRow | undefined {
  if (!arr.length) return undefined
  return arr?.reduce((result: IBaseReportRow | undefined, n) => {
    if (result) return result
    if (n.key === key) return n
    if (n.type === 'Section') return treeFind(n.rows, key)
    return undefined
  }, undefined)
}

const ErrorMessage = () => {
  const navigate = useNavigate()

  return (
    <Result
      status={500}
      title="Server Error"
      subTitle="Something went wrong. Please try again later or contact support."
      extra={
        <Button type="primary" onClick={() => navigate(-1)}>
          Back
        </Button>
      }
    />
  )
}
