import { useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Flex, Row, Table, Input } from 'antd'
import { useAdminUser } from '../../hooks/useAdminUser'
import type { ColumnsType } from 'antd/es/table'

import { api } from 'utils/axios'
import { formatDate, sortByDate } from 'utils/dates'
import { downloadBlob } from 'utils/download'
import type { IUser } from 'types/user.types'
import { toCurrency } from 'utils/numbers'

export function UsersTab() {
  const users = useAdminUser()
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)

  const download = async () => {
    setLoading(true)
    return api
      .get('admin/users/export', { responseType: 'blob' })
      .then((r) => downloadBlob(r.data, 'equility-users.xls'))
      .finally(() => setLoading(false))
  }

  const filteredData = users.data?.filter(user => {
    const searchLower = searchTerm.toLowerCase()
    return (
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchLower) ||
      user.email.toLowerCase().includes(searchLower) ||
      (user.phoneNumber || '').toLowerCase().includes(searchLower) ||
      (user.member?.team?.name || '').toLowerCase().includes(searchLower)
    )
  })

  return (
    <Flex vertical gap={10}>
      <Row gutter={24}>
        <Col span={8}>
          <Input
            placeholder="Search by name, email, phone number or team..."
            prefix={<SearchOutlined />}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            allowClear
          />
        </Col>
        <Col span={12}>
          <Divider orientation="left">{filteredData?.length} total</Divider>
        </Col>
        <Col span={4}>
          <Flex vertical justify="center" style={btnFlexStyle}>
            <Button block onClick={download} loading={loading}>
              Download
            </Button>
          </Flex>
        </Col>
      </Row>

      <Table
        dataSource={filteredData}
        columns={columns}
        rowKey="id"
        loading={users.isLoading}
        scroll={{ x: 'max-content' }}
      />
    </Flex>
  )
}

const columns: ColumnsType<IUser> = [
  {
    title: 'Name',
    dataIndex: 'firstName',
    width: 220,
    ellipsis: true,
    render: (name, record) => `${name} ${record.lastName}`,
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    ellipsis: true,
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: 'Last access',
    dataIndex: 'lastAccess',
    render: (date) => (date ? formatDate(date) : '-'),
    sorter: (a, b) => sortByDate(a.lastAccess, b.lastAccess),
    width: 140,
  },
  {
    title: 'Team',
    dataIndex: ['member', 'team', 'name'],
    width: 130,
    ellipsis: true,
  },
  {
    title: 'Role',
    dataIndex: ['member', 'role'],
    width: 90,
    render: (role, user) => {
      if (user.isOwner) return 'Owner'
      if (role === 0) return 'Admin'
      if (role === 1000) return 'Member'
      if (role === 2000) return 'Guest'
    },
  },
  {
    title: 'Companies',
    dataIndex: 'companies',
    width: 110,
    render: (companies) => companies,
  },
  {
    title: 'Tier',
    dataIndex: 'accessLevel',
    width: 100,
    sorter: (a, b) => a.accessLevel - b.accessLevel,
    render: (level) => {
      if (level === 1) return 'Pro'
      if (level === 2) return 'Advanced'
      return ''
    },
  },
  {
    title: 'Value',
    dataIndex: 'charge',
    width: 100,
    sorter: (a, b) => (a.charge || 0) - (b.charge || 0),
    render: (v) => toCurrency(v),
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    ellipsis: true,
    sorter: (a, b) => {
      // If neither has a phone number, they're equal
      if (!a.phoneNumber && !b.phoneNumber) return 0;
      // If only a has a phone number, it comes first
      if (a.phoneNumber && !b.phoneNumber) return -1;
      // If only b has a phone number, it comes first
      if (!a.phoneNumber && b.phoneNumber) return 1;
      // If both have phone numbers, sort them normally
      return a.phoneNumber.localeCompare(b.phoneNumber);
    },
  },
  {
    title: 'Created',
    dataIndex: 'createdOn',
    render: (createdOn) => formatDate(createdOn, 'll'),
    width: 140,
  },
]

const btnFlexStyle: React.CSSProperties = {
  height: '100%',
}
