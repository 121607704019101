import useSWR, { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'

import { api } from 'utils/axios'
import { useCompanyStore } from 'store/company.store'
import type { IReconcile } from 'types/reconcile'

interface IParams {
  id: number | null
}

interface IUpdateData {
  arg: Partial<IReconcile>
}

// One

export function useReconcile(params: IParams) {
  const { mutate: revalidate } = useSWRConfig()

  const companyId = useCompanyStore((state) => state.id)
  const id = params.id
  const key = id ? `/reconciliation/${id}?companyId=${companyId}` : null

  const allKey = '/reconciliation?companyId=' + companyId

  const reconcile = useSWR<IReconcile>(key, { revalidateOnFocus: false })
  const createMutation = useSWRMutation(allKey, createFetcher)
  const deleteMutation = useSWRMutation(allKey, deleteFetcher)
  const updateMutation = useSWRMutation(key, updateFetcher)

  const options = { onSuccess: () => revalidate(allKey) }

  return {
    data: reconcile.data,
    create: (data: Partial<IReconcile>) => createMutation.trigger(data, options),
    update: (data: Partial<IReconcile>) => updateMutation.trigger(data, options),
    delete: (data: Partial<IReconcile>) => deleteMutation.trigger(data, options),
    isLoading: reconcile.isValidating || createMutation.isMutating || deleteMutation.isMutating,
    error: reconcile.error || createMutation.error || deleteMutation.error,
  }
}

// FindAll

export function useReconcileList() {
  const companyId = useCompanyStore((state) => state.id)
  const key = companyId ? '/reconciliation?companyId=' + companyId : null
  return useSWR<IReconcile[]>(key)
}

// API

const createFetcher = (url: string, { arg }: IUpdateData) => {
  return api.post<IReconcile>(url, arg).then((res) => res.data)
}

const updateFetcher = (url: string, { arg }: IUpdateData) => {
  return api.put<IReconcile>(url, arg).then((res) => res.data)
}

const deleteFetcher = (url: string, { arg }: IUpdateData) => {
  const _url = url.split('?')
  return api.delete(_url[0] + '/' + arg.id + '?' + url.split('?')[1]).then((res) => res.data)
}
