import { Select } from 'antd'

interface Props extends React.ComponentProps<typeof Select> {}

export function SelectMethod(props: Props) {
  return <Select options={options} {...props} />
}

export const options = [
  {
    value: 'Cash',
    label: 'Cash-basis',
  },
  {
    value: 'Accrual',
    label: 'Accrual-basis',
  },
]

export function getMethodLabel(value: string) {
  return options.find((o) => o.value === value)?.label
}
