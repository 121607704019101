import { useEffect, useMemo, useState } from 'react'
import { Col, Flex, Form, Input, Modal, Row, Select, Steps, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { SelectProps } from 'antd/lib'

import { useCompanies } from 'hooks/useCompanies'
import { useTeamInvite } from 'hooks/useTeam'
import { ICompany } from 'types/company.types'
import { RoleType } from 'types/user.types'
import { getRoleText } from 'utils/team'

const { Text } = Typography

interface Props {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
}

interface FormValues {
  email: string
  role: RoleType
  companies: number[]
}

function InviteTeamMateModal(props: Props) {
  const { onClose, open, onSuccess } = props
  const [form] = useForm<FormValues>()

  const { data: companies } = useCompanies()
  const { invite: inviteTeammate, inviteLoading } = useTeamInvite()

  const [step, setStep] = useState(0)
  const [values, setValues] = useState<FormValues>({
    role: 0,
    email: '',
    companies: [],
  })

  const payload = useMemo(
    () => ({
      ...values,
      companies:
        values.companies.map((comp) => {
          const company = companies?.find((c) => c.id === comp)
          return { id: company?.id, name: company?.name }
        }) || [],
    }),
    [values, companies],
  )

  const onFinish = (formValues: FormValues) => {
    if (step === 3) {
      inviteTeammate(payload).then(() => {
        props.onClose()
        onSuccess?.()
      })
      return
    }

    let newPayload = { ...values, ...formValues }
    setValues(newPayload)
    setStep((prev) => prev + 1)
  }

  const initialValues: FormValues = { email: '', role: RoleType.Member, companies: [] }

  // on company access step active check to select/deselect all companies
  useEffect(() => {
    if (step !== 2) {
      return
    }

    const isAdminRole = values?.role === RoleType.Admin

    if (isAdminRole) {
      form.setFieldValue(
        'companies',
        companies?.map((comp) => comp.id),
      )
    } else {
      form.setFieldValue('companies', [])
    }
  }, [step])

  // on modal close reset form and state values
  useEffect(() => {
    if (!open) {
      setStep(0)
      setValues({ role: 0, email: '', companies: [] })
      form.resetFields(['email', 'role', 'companies'])
    }
  }, [open])

  return (
    <Modal
      width={700}
      open={open}
      onCancel={onClose}
      title="Invite teammate"
      okText={step === 3 ? 'Send Invite' : 'Next'}
      cancelText="Previous"
      okButtonProps={{ form: 'invite-form', htmlType: 'submit', loading: inviteLoading }}
      cancelButtonProps={{ htmlType: 'button', disabled: step === 0, onClick: () => setStep((prev) => prev - 1) }}
    >
      <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues} id="invite-form">
        <Steps
          current={step}
          onChange={setStep}
          size="small"
          items={[
            { title: 'Member Info' },
            { title: 'Member Role', disabled: step < 1 },
            { title: 'Company Access', disabled: step < 2 },
            { title: 'Review Details', disabled: step < 3 },
          ]}
          style={{ marginTop: '24px', marginBottom: '24px' }}
        />

        <Row gutter={16}>
          <Col span={24}>
            {step === 0 && <MemberInfoStep />}
            {step === 1 && <MemberRoleStep />}
            {step === 2 && <CompanyAccessStep companies={companies} disabled={values?.role === RoleType.Admin} />}
            {step === 3 && <ReviewDetailsStep {...payload} />}
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

function MemberInfoStep() {
  return (
    <Form.Item
      label="Email"
      name="email"
      rules={[{ required: true, message: 'Please input valid email', type: 'email' }]}
    >
      <Input placeholder="example@gmail.com" />
    </Form.Item>
  )
}

function MemberRoleStep() {
  const roleOptions: SelectProps['options'] = [
    {
      label: getRoleText(RoleType.Admin),
      value: RoleType.Admin,
      description:
        'Full access to Equility, and the ability to manage organization settings, company settings, and to add/delete users',
    },
    {
      label: getRoleText(RoleType.Member),
      value: RoleType.Member,
      description: 'Full access to Equility, and the ability to manage company settings',
    },
    {
      label: getRoleText(RoleType.ViewOnly),
      value: RoleType.ViewOnly,
      description: 'Read-only access to financial review reports and company analytics',
    },
  ]

  return (
    <Form.Item label="Role" name="role" rules={[{ required: true }]}>
      <Select
        placeholder="Select an option"
        options={roleOptions}
        optionFilterProp="label"
        optionRender={(option: any) => (
          <Flex vertical>
            <Text>{option.label}</Text>
            <Text type="secondary" style={{ whiteSpace: 'normal' }}>
              {option.data.description}
            </Text>
          </Flex>
        )}
      />
    </Form.Item>
  )
}

function CompanyAccessStep({ companies, disabled }: { companies: ICompany[] | undefined; disabled: boolean }) {
  const companyOptions = companies?.map((opt) => ({
    label: opt.name,
    value: opt.id,
  }))

  return (
    <Form.Item label="Companies" name="companies">
      <Select
        placeholder="Select companies"
        disabled={disabled}
        mode="multiple"
        showSearch={true}
        dropdownStyle={{ minWidth: 200 }}
        optionFilterProp="label"
        options={companyOptions}
      />
    </Form.Item>
  )
}

function ReviewDetailsStep(props: {
  role: number
  email: string
  companies: { id: number | undefined; name: string | undefined }[]
}) {
  const selectedCompanies = props?.companies.map((comp) => comp.name) || []
  const selectedCompaniesText = selectedCompanies.join(',')

  return (
    <Flex vertical>
      <Typography.Text>
        <b> Email</b>: {props?.email}
      </Typography.Text>
      <Typography.Text>
        <b> Role</b> : {props?.role !== undefined && getRoleText(props.role)}
      </Typography.Text>
      {selectedCompanies.length ? (
        <Typography.Text>
          <b>Companies</b> : {selectedCompaniesText}
        </Typography.Text>
      ) : null}
    </Flex>
  )
}

export default InviteTeamMateModal
