import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

import { AppLayout } from './AppLayout'
import { FullLoader } from 'components/FullLoader'

import { useAuthStore } from 'store/auth.store'
import { auth } from 'utils/firebase'

export function Protected() {
  const isAuth = useAuthStore((state) => !!state.uid)
  const [fbUser, setFbUser] = useState<any>(null)

  const location = useLocation()
  const signInUrl = `/signin?returnUrl=${location.pathname + location.search}`

  useEffect(() => {
    if (!isAuth) return
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setFbUser(user)
    })

    return () => unsubscribe()
  }, [])

  if (!isAuth) return <Navigate to={signInUrl} />
  if (!fbUser) return <FullLoader />
  if (!fbUser.emailVerified) return <Navigate to={'verify-email'} />

  return <AppLayout></AppLayout>
}
