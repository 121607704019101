import { Card, Divider, Flex, List, Typography } from 'antd'

const { Text } = Typography

export function Payroll() {
  return (
    <Flex vertical>
      <Divider orientation="left">Integration</Divider>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={items2}
        renderItem={(item) => (
          <List.Item key={item.title}>
            <Card
              cover={
                <Flex justify="center" align="center" style={cardStyle}>
                  <img style={item.disabled ? imageStyleDisabled : imageStyle} alt={item.title} src={item.image} />
                  {item.disabled && <Text style={cardStyleDisabledText}>Coming soon</Text>}
                </Flex>
              }
            />
          </List.Item>
        )}
      />
      {/* <Divider orientation="left">Settings</Divider> */}
    </Flex>
  )
}

const items2 = [
  {
    image: require('assets/gusto.png'),
    title: 'Gusto',
    disabled: true,
  },
  {
    image: require('assets/quickbooks_payroll.png'),
    title: 'Quickbooks payroll',
    disabled: true,
  },
]

const cardStyle: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  height: 90,
  marginBottom: -50,
}

const cardStyleDisabledText: React.CSSProperties = {
  position: 'absolute',
  bottom: 2,
  right: 7,
}

const imageStyle: React.CSSProperties = {
  width: 140,
}

const imageStyleDisabled: React.CSSProperties = {
  ...imageStyle,
  opacity: 0.3,
}
