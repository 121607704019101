import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';

interface AnimatedProgressProps {
  percent: number;
  strokeWidth?: number;
  style?: React.CSSProperties;
}

const AnimatedProgress: React.FC<AnimatedProgressProps> = ({
                                                             percent,
                                                             strokeWidth = 10,
                                                             style
                                                           }) => {
  const [currentPercent, setCurrentPercent] = useState<number>(0);

  useEffect(() => {
    // Start from 0
    setCurrentPercent(0);

    const targetPercent = Math.round(percent);
    const duration = 1000; // 1 seconds
    const stepDuration = 50; // Update every 50ms for smooth animation
    const steps = duration / stepDuration;
    const increment = Math.ceil(targetPercent / steps);

    const timer = setInterval(() => {
      setCurrentPercent((prev) => {
        const next = prev + increment;
        if (next >= targetPercent) {
          clearInterval(timer);
          return targetPercent;
        }
        return next;
      });
    }, stepDuration);

    return () => clearInterval(timer);
  }, [percent]);

  return (
    <div
      className="transition-transform hover:scale-105 duration-300"
      style={{ marginLeft: 20, ...style }}
    >
      <Progress
        type="circle"
        strokeWidth={strokeWidth}
        percent={Math.round(currentPercent)}
        className="transition-all duration-100 ease-linear"
      />
    </div>
  );
};

export default AnimatedProgress;