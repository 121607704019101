import { toSafeDecimal } from './numbers'

import { CsvBoxTx, ITx } from 'types/tx.types'
import { IReport, IReportRowData } from 'types/report.type'
import { formatDate } from './dates'

export function toTransaction() {}

export function csvBoxToTxs(data: CsvBoxTx[], invertAmount?: boolean) {
  return data.map((v, i) => {
    const id = uid()
    const amount = toRegularNumber(v.Amount)
    const expence = toRegularNumber(v.Expence)
    const received = toRegularNumber(v.Received)
    const spent = v.Spent ? 0 - toRegularNumber(v.Spent) : 0
    const payment = v.Payment ? 0 - toRegularNumber(v.Payment) : 0

    const total = amount || expence || received || spent || payment

    return {
      _id: id,
      id: id,
      date: formatDate(v.Date, 'YYYY-MM-DD'),
      amount: toSafeDecimal(invertAmount ? 0 - total : total),
      description: v.Description,
      memo: v.Description,
      matchedTo: [],
    }
  })
}

function toRegularNumber(value?: string) {
  if (!value) return 0

  const v = value.replaceAll(/,/g, '') // remove commas

  if (v.includes('(')) {
    return 0 - parseFloat(v.replace(/\(|\)/g, ''))
  }
  if (v.includes('-')) {
    return parseFloat(v)
  }
  return parseFloat(v)
}

export function csvToTxs(data: string[][], bankFormat: any, invertAmount?: boolean): ITx[] {
  const [header, ...list] = data

  const dateIndex = header.indexOf(bankFormat.date)
  const amountIndex = header.indexOf(bankFormat.amount)
  const descriptionIndex = header.indexOf(bankFormat.description)

  return list.map((v, i) => {
    const amount = parseFloat(v[amountIndex])
    return {
      _id: i + v[dateIndex] + v[amountIndex],
      id: i,
      date: formatDate(v[dateIndex], 'YYYY-MM-DD'),
      amount: invertAmount ? 0 - amount : amount,
      description: v[descriptionIndex],
      memo: v[descriptionIndex],
      matchedTo: [],
    }
  })
}

export function pladToTxs(data: [], invertAmount?: boolean): ITx[] {
  return data.map((v: any) => {
    return {
      _id: v.transaction_id,
      id: v.transaction_id,
      date: formatDate(v.date, 'YYYY-MM-DD'),
      amount: invertAmount ? 0 - v.amount : v.amount,
      description: v.original_description || v.payment_meta?.reason || v.name || '',
      memo: v.original_description || v.payment_meta?.reason || v.name || '',
      matchedTo: [],
    }
  })
}

export function reportToTsx(data: IReport, accId: string, unreconciled?: boolean): ITx[] {
  const exclude = ['Beginning Balance', 'Ending Balance', 'Total', '0-00-00']
  if (!data.report) return []
  const rows = (data.report.find((v) => v.id === accId)?.rows as IReportRowData[]) || []
  const _rows = unreconciled ? rows.filter((v) => v.is_cleared !== 'R') : rows
  return _rows
    .filter((v: IReportRowData) => !!v.tx_date && !exclude.includes(v.tx_date))
    .map((v: IReportRowData) => {
      const amount = parseFloat(v.subt_nat_amount || v.subt_nat_home_amount || v.amount || '0')
      return {
        _id: uid(),
        id: v.id,
        matchedTo: [],
        date: formatDate(v.tx_date, 'YYYY-MM-DD'),
        memo: v.memo,
        amount: amount,
        is_cleared: v.is_cleared,
        type: v.txn_type,
        num: v.doc_num,
        account: v.split_acc,
        name: v.cust_name || v.vend_name || v.name || '',
        temp: v.tx_date,
      }
    })
}

export function qboToTxs(data: []): ITx[] {
  return data
    .filter((v: any) => v.Amount)
    .map((v: any) => {
      return {
        _id: uid(),
        matchedTo: [],
        description: v.memo,
        ...v,
      }
    })
}

export function uid() {
  let a = new Uint32Array(3)
  window.crypto.getRandomValues(a)
  return (
    performance.now().toString(36) +
    Array.from(a)
      .map((A) => A.toString(36))
      .join('')
  ).replace(/\./g, '')
}
