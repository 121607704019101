import { useEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Alert, Button, Col, DatePicker, Divider, Flex, notification } from 'antd'
import { Form, List, Switch, Row } from 'antd'

import { SelectEntity } from 'components/SelectEntity'
import { SelectMethod } from 'components/SelectMethod'
import { AlertPlugin } from 'components/AlertPlugin'

import { useRole } from 'hooks/useRole'
import { useCompany } from 'hooks/useCompany'
import { formatDate, parseDateRage } from 'utils/dates'
import { isPluginInstalled, runReviewSync } from 'utils/plugin'
import { ConnectionType } from 'types/company.types'
import { RoleType } from 'types/user.types'
import { useFlags } from 'flagsmith/react'

const { RangePicker } = DatePicker

const dateRange = parseDateRage()

export function ReviewRun() {
  const navigate = useNavigate()

  const flags = useFlags(['disable_chrome_plugin', 'hide_shopify'])

  const [searchParams] = useSearchParams()
  const search: SearchParams = Object.fromEntries(searchParams) as any

  const { company } = useCompany()
  const { isAllowed } = useRole(RoleType.Member)

  const [form] = Form.useForm()

  const entity = company?.entity
  const method = company?.settings?.accountingMethod || 'Cash'

  const qbo = (company?.connections || []).find((i) => i.type.id === ConnectionType.QBO)
  const isConnected = qbo?.status === 1

  const rutter = company?.connections?.filter((i) => i.type.id === ConnectionType.Rutter)
  const isShopifyConnected = rutter?.some((i) => i.status === 1)

  const sourceData = [
    {
      title: 'QuickBooks Online',
      description: 'Accounting data',
      active: true,
      disabled: true,
    },
    {
      title: 'Shopify',
      description: 'E-commerce data',
      active: false,
      disabled: flags.hide_shopify.enabled,
    },
  ]

  sourceData[0].active = !!isConnected
  sourceData[1].active = !!isShopifyConnected

  const isPlugin = isPluginInstalled()

  useEffect(() => {
    if (!company) return
    if (search.daterange && search.sync) {
      const daterange = search.daterange.split('--')
      runReview({ from: daterange[0], to: daterange[1], method, entity })
      localStorage.removeItem('qbosync')
    }
  }, [search, company])

  const runReview = (state: any) => {
    const path = `/company/${company?.id}/review/result`
    navigate(path, { state })
  }

  const onSubmit = (values: any) => {
    if (!company) return
    if (!isConnected && !flags?.disable_chrome_plugin?.enabled) return notification.error({ message: 'Error', description: 'QuickBooks Online is not connected' })

    const state = {
      from: formatDate(values.daterange[0], 'YYYY-MM-DD'),
      to: formatDate(values.daterange[1], 'YYYY-MM-DD'),
      method: values.method,
      entity: values.entity || undefined,
    }

    if (!isPlugin) {
      if (!flags?.disable_chrome_plugin?.enabled) {
        notification.warning({ message: 'Warining', description: 'Equility Plugin is not installed' })
      }
      runReview(state)
    } else {
      const connectionInfo = qbo?.connectionInfo || '{}'
      runReviewSync({
        from: state.from,
        to: state.to,
        companyId: company.id,
        realmId: connectionInfo.realm_id,
        method: state.method,
      })
    }
  }

  if (!company?.id) return null
  if (!isAllowed) return null

  return (
    <Flex vertical>
      {!isConnected && !flags?.disable_chrome_plugin?.enabled &&  (
        <Alert
          message="Quickbooks Integration"
          description="Connect your Accounting platform to use the features"
          type="warning"
          showIcon
        />
      )}
      <AlertPlugin />
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Row gutter={24}>
          <Col span={16}>
            <Divider orientation="left">Review Parameters</Divider>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Date Range" name="daterange" initialValue={dateRange}>
                  <RangePicker style={inputStyle} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Accounting Method" name="method" initialValue={method}>
                  <SelectMethod />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Divider orientation="left">Business info</Divider>
            <Form.Item label="Business entity type" name="entity" initialValue={entity}>
              <SelectEntity disabled />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Source data</Divider>
        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={sourceData}
          renderItem={(item) => (
            <List.Item>
              <Flex vertical gap={10}>
                <List.Item.Meta title={item.title} description={item.description} />
                <Flex>
                  <Switch value={item.active} disabled={item.disabled} />
                </Flex>
              </Flex>
            </List.Item>
          )}
        />
        <Divider />
        <Flex justify="space-between">
          <Button type="primary" style={runStyle} htmlType="submit">
            Run
          </Button>
          <Link to="../settings/integrations" relative="path">
            <Button>Setup source data</Button>
          </Link>
        </Flex>
      </Form>
    </Flex>
  )
}


const runStyle: React.CSSProperties = {
  width: 200,
}

const inputStyle: React.CSSProperties = {
  width: '100%',
}

interface SearchParams {
  daterange: string
  sync: string
}
