import { useEffect } from 'react'
import { Navigate, useMatch } from 'react-router-dom'
import { Outlet } from 'react-router'

import { FullLoader } from 'components/FullLoader'

import { useCompanyStore } from 'store/company.store'
import { useAccessLevel } from 'hooks/useAccessLevel'
import { useAccounts } from 'hooks/useAccounts'

export function CompanyPage() {
  const match = useMatch('company/:id/*')
  const companyId = parseInt(match?.params.id || '')
  const setCompanyId = useCompanyStore((state) => state.setCompany)
  const accounts = useAccounts()

  const { accessLevel, isLoading } = useAccessLevel()

  console.log('CompanyPage accounts count', companyId, accounts.accounts.length)

  useEffect(() => {
    if (companyId) setCompanyId(companyId)
  }, [companyId])

  if (isLoading) return <FullLoader />
  if (!accessLevel) return <Navigate to="/" />

  return <Outlet />
}
