import React, { useEffect, useState } from 'react'
import { Button, Card, Divider, Flex, Spin, Typography, notification } from 'antd'
import { PlaidEmbeddedLink } from 'react-plaid-link'
import type { PlaidLinkOnSuccessMetadata } from 'react-plaid-link'
 
import { api } from 'utils/axios'
import { useNewCompany } from '.'
import { usePlaidLinkToken } from 'hooks/usePlaid'
import { getErrorMsg } from 'utils/geterror'
import { ConnectionType } from 'types/company.types'
import { FullLoader } from 'components/FullLoader'

const { Text } = Typography

interface IProps {
  onNext: () => void
  onBack: () => void
}

export function BanksStep(props: IProps) {
  const { token: tokenData } = usePlaidLinkToken()

  const token = tokenData?.linkToken

  const company = useNewCompany(s => s.company)
  const setCompany = useNewCompany(s => s.setCompany)

  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState<IPlaidItem[]>([])

  useEffect(() => {
    const plaidItems = (company?.connections || [])
      .filter((i) => i.type.id === ConnectionType.Plaid)
      .map(v => {
        return {
          keyId: v.keyId,
          status: v.status,
          ...v.connectionInfo
        }
      })

    setMeta(plaidItems as IPlaidItem[])
  }, [company?.connections])

  const onConnect = (token: string) => {
    if (!company?.id) return
    setLoading(true)
    const postData = { token, provider: 'Plaid' }
    api.post(`companies/${company.id}/connection/`, postData)
      .then(() => {
        notification.success({ message: 'Connected to Plaid' })
        return api.get(`companies/${company?.id}`)
          .then(r => setCompany(r.data))
      })
      .catch(err => notification.error(getErrorMsg(err)))
      .finally(() => setLoading(false))
  }

  const onDelete = (m: IPlaidItem) => {
    if (!company?.id) return
    setLoading(true)
    api.delete(`companies/${company.id}/connection/${m.keyId}`)
      .then(() => {
        notification.warning({ message: 'Deleted' })
        return api.get(`companies/${company?.id}`)
          .then(r => setCompany(r.data))
      })
      .catch(err => notification.error(getErrorMsg(err)))
      .finally(() => setLoading(false))
  }

  if (!token) return <FullLoader />

  const extra = (m: IPlaidItem) => (
    <Flex gap={20} align="center" justify="center">
      <Text type="secondary">Status: {m.status === 1 ? 'active' : 'pending'}</Text>
      <Button danger size="small" onClick={() => onDelete(m)} loading={loading}>Delete</Button>
    </Flex>
  )

  return (
    <Flex vertical>
      <Divider orientation="left">Connect your banks and cards</Divider>
      <PlaidEmbeddedLink token={token} style={plaidStyle} onSuccess={onConnect} />
      <Text><small>* Please remember that we cannot and will not make any modifications to your data. Our integrations are needed to pull financial data and our access can be revoked at any time.</small>​</Text>
      {!!meta.length && <Divider orientation="left">Configuration</Divider>}
      <Spin spinning={loading}>
        <Flex vertical gap={10}>
          {meta.map((m, i) => (
            <Card key={i} title={m.institution?.name || 'Accounts'} extra={extra(m)} size='small'>
              <Flex vertical gap={10}>
                {m.accounts.map((a, j) => (
                  <Text key={j}>{a.name} <small className='dimmed'>(*{a.mask})</small></Text>
                ))}
              </Flex>
            </Card>
          ))}
        </Flex>
      </Spin>
      <Divider />
      <Flex gap={10}>
        <Button onClick={props.onBack}>Back</Button>
        {!meta.length ? (
          <Button type="primary" onClick={props.onNext}>Skip</Button>
        ) : (
          <Button type="primary" onClick={props.onNext}>Next</Button>
        )}
      </Flex>
    </Flex>
  )
}

const plaidStyle: React.CSSProperties = {
  height: 350
}

interface IPlaidItem {
  item_id: string
  institution: PlaidLinkOnSuccessMetadata['institution']
  accounts: PlaidLinkOnSuccessMetadata['accounts']
  keyId: string
  status: number
}
