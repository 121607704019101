import useSWR, { useSWRConfig } from 'swr'
import useSWRMutation from 'swr/mutation'
import { useDebouncedCallback } from 'use-debounce'

import { useCompanyStore } from 'store/company.store'
import { ICompany } from 'types/company.types'
import { api } from 'utils/axios'

export function useCompany(revalidateOnFocus = true) {
  const { mutate: revalidate } = useSWRConfig()

  const companyId = useCompanyStore((state) => state.id)

  const key = companyId ? `companies/${companyId}` : null
  const { data, isLoading } = useSWR<ICompany>(key, { revalidateOnFocus })
  const { trigger, isMutating } = useSWRMutation(key, updateFetcher)

  const update = useDebouncedCallback((data: Partial<ICompany>) => {
    trigger(data, { optimisticData: data, onSuccess: () => revalidate('companies') })
  }, 1000)

  const createMutation = useSWRMutation('companies', createFetcher)
  const create = (data: Partial<ICompany> = {}) =>
    createMutation.trigger(data, {
      onSuccess: () => revalidate('companies'),
    })

  const deleteMutation = useSWRMutation('companies', deleteFetcher)
  const remove = (data: Partial<ICompany> = {}) =>
    deleteMutation.trigger(data, {
      onSuccess: () => revalidate('companies'),
    })

  return {
    revalidate: () => revalidate(key),
    update,
    create,
    remove,
    company: data,
    isLoading: isLoading || isMutating,
  }
}

interface IUpdateData {
  arg: Partial<ICompany>
}

const updateFetcher = (url: string, { arg }: IUpdateData) => {
  return api.put<ICompany>(url, arg).then((res) => res.data)
}

const createFetcher = (url: string, { arg }: IUpdateData) => {
  return api.post<ICompany>(url, arg).then((res) => res.data)
}

const deleteFetcher = (baseUrl: string, { arg }: IUpdateData) => {
  const url = baseUrl + '/' + arg.id
  return api.delete<ICompany>(url).then((res) => res.data)
}
