import React, { memo } from 'react';
import {
  LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer,
  BarChart, Bar, AreaChart, Area, CartesianGrid, Legend
} from 'recharts';
import { Spin, Result } from 'antd';
import { CustomTooltip } from './ChartCustomTooltip';

type ChartType = 'line' | 'bar' | 'area';

interface AccountData {
  name: string;
  [key: string]: string | number;
}

interface AccountInfo {
  name: string;
  [key: string]: any;
}

interface ChartRendererProps {
  type?: ChartType;
  accountsChartData: AccountData[];
  accountsData: AccountInfo[];
  chartDataLoading?: boolean;
}

const CHART_COLORS = [
  '#52c41a',  // ant success green
  '#faad14',  // ant warning yellow
  '#eb2f96',  // ant pink
  '#722ed1',  // ant purple
  '#13c2c2',  // ant cyan
  '#f5222d',  // ant red
  '#fa541c',  // ant volcano
  '#fa8c16',  // ant orange
  '#a0d911',  // ant lime
  '#1677ff',  // ant primary blue

  // Secondary Variations
  '#2f54eb',  // geekblue-6
  '#08979c',  // cyan-6
  '#d4380d',  // volcano-7
  '#389e0d',  // green-7
  '#531dab',  // purple-7
  '#1d39c4',  // indigo-7
  '#d48806',  // gold-7
  '#d4b106',  // yellow-7
  '#cf1322',  // red-7
  '#c41d7f',  // magenta-7

  // Lighter Tones
  '#85a5ff',  // geekblue-4
  '#5cdbd3',  // cyan-4
  '#ffa39e',  // red-4
  '#b7eb8f',  // green-4
  '#d3adf7',  // purple-4
  '#adc6ff',  // indigo-4
  '#ffd666',  // gold-4
  '#fff566',  // yellow-4
  '#ff7875',  // red-4
  '#ff85c0',  // magenta-4

  // Darker Tones
  '#10239e',  // geekblue-8
  '#006d75',  // cyan-8
  '#a8071a',  // red-8
  '#237804',  // green-8
  '#391085',  // purple-8
  '#10239e',  // indigo-8
  '#ad6800',  // gold-8
  '#ad8b00',  // yellow-8
  '#a8071a',  // red-8
  '#9e1068'   // magenta-8
];


const ChartRenderer: React.FC<ChartRendererProps> = ({
                                                       type = 'line',
                                                       accountsChartData = [],
                                                       accountsData = [],
                                                       chartDataLoading = false,
                                                     }) => {
  // Calculate dynamic height based on the number of legend items
  const getChartHeight = () => {
    const baseHeight = 300;
    const itemsPerRow = Math.floor((window.innerWidth - 100) / 200); // Approx width per legend item
    const rows = Math.ceil(accountsData.length / itemsPerRow);
    const additionalHeight = Math.max(0, rows - 1) * 25; // 25px per additional row
    return baseHeight + additionalHeight;
  };

  const formatYAxisTick = (value: number) => {
    if (Math.abs(value) >= 1000) {
      return `${Math.sign(value) * Math.round(Math.abs(value) / 1000)}k`
    }
    return value.toString();
  };

  if (chartDataLoading) {
    return <Spin spinning={true} />;
  }

  if (!accountsChartData?.length && !chartDataLoading) {
    return <Result icon={false} title="No report data!" />;
  }

  const commonChartProps = {
    data: accountsChartData,
    margin: { top: 5, right: 20, bottom: 5, left: 0 }
  };

  const renderLineChart = () => (
    <LineChart {...commonChartProps}>
      <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
      <XAxis
        dataKey="name"
        tick={{ fill: 'rgba(0,0,0,.45)' }}
        tickLine={{ stroke: '#d9d9d9' }}
      />
      <YAxis
        tickFormatter={formatYAxisTick}
        tick={{ fill: 'rgba(0,0,0,.45)' }}
        tickLine={{ stroke: '#d9d9d9' }}
      />
      <Tooltip content={<CustomTooltip />} />
      <Legend
        height={36}
        wrapperStyle={{
          paddingTop: '10px',
          paddingBottom: '10px'
        }}
      />
      {accountsData.map((account, index) => (
        <Line
          key={`line-${index}`}
          type="monotone"
          dataKey={account.name}
          name={account.title || account.name}  // Use title if available
          stroke={CHART_COLORS[index % CHART_COLORS.length]}
          strokeWidth={2}
          dot={{ strokeWidth: 2, r: 4, fill: 'white' }}
          activeDot={{ r: 8 }}
        />
      ))}
    </LineChart>
  );

  const renderBarChart = () => (
    <BarChart {...commonChartProps}>
      <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
      <XAxis
        dataKey="name"
        tick={{ fill: 'rgba(0,0,0,.45)' }}
        tickLine={{ stroke: '#d9d9d9' }}
      />
      <YAxis
        tickFormatter={formatYAxisTick}
        tick={{ fill: 'rgba(0,0,0,.45)' }}
        tickLine={{ stroke: '#d9d9d9' }}
      />
      <Tooltip content={<CustomTooltip />} />
      <Legend
        height={36}
        wrapperStyle={{
          paddingTop: '10px',
          paddingBottom: '10px'
        }}
      />
      {accountsData.map((account, index) => (
        <Bar
          key={`bar-${index}`}
          stackId={`bar-${index}`}
          dataKey={account.name}
          name={account.title || account.name}  // Use title if available
          fill={CHART_COLORS[index % CHART_COLORS.length]}
          opacity={0.8}
        />
      ))}
    </BarChart>
  );

  const renderAreaChart = () => (
    <AreaChart {...commonChartProps}>
      <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
      <XAxis
        dataKey="name"
        tick={{ fill: 'rgba(0,0,0,.45)' }}
        tickLine={{ stroke: '#d9d9d9' }}
      />
      <YAxis
        tickFormatter={formatYAxisTick}
        tick={{ fill: 'rgba(0,0,0,.45)' }}
        tickLine={{ stroke: '#d9d9d9' }}
      />
      <Tooltip content={<CustomTooltip />} />
      <Legend
        height={36}
        wrapperStyle={{
          paddingTop: '10px',
          paddingBottom: '10px'
        }}
      />
      {accountsData.map((account, index) => (
        <Area
          key={`area-${index}`}
          type="monotone"
          dataKey={account.name}
          name={account.title || account.name}  // Use title if available
          fill={CHART_COLORS[index % CHART_COLORS.length]}
          stackId={`area-${index}`}
          stroke={CHART_COLORS[index % CHART_COLORS.length]}
          strokeWidth={2}
          opacity={0.8}
        />
      ))}
    </AreaChart>
  );

  const getChartComponent = () => {
    switch (type) {
      case 'line':
        return renderLineChart();
      case 'bar':
        return renderBarChart();
      case 'area':
        return renderAreaChart();
      default:
        return renderLineChart();
    }
  };

  return (
    <ResponsiveContainer width="100%" height={getChartHeight()}>
      {getChartComponent()}
    </ResponsiveContainer>
  );
};

export default memo(ChartRenderer);