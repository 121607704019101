import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Flex, Typography, notification } from 'antd'

import { usePrompt } from 'hooks/useBlock'
import { useRole } from 'hooks/useRole'
import { useAccount } from 'hooks/useAccounts'
import { useCompany } from 'hooks/useCompany'
import { useReconcile } from 'hooks/useReconcile'
import { getErrorMsg } from 'utils/geterror'
import { formatDate } from 'utils/dates'
import { isPluginInstalled } from 'utils/plugin'
import { ConnectionType } from 'types/company.types'
import { RoleType } from 'types/user.types'
import type { IMatcher } from 'types/matcher'
import type { IReconcile } from 'types/reconcile'
import type { ICompanyConnection } from 'types/company.types'

interface Props {
  data: IReconcile
  matcher: IMatcher
}

const { Text } = Typography

export function Details(props: Props) {
  const { data, matcher } = props

  const navigate = useNavigate()

  const { company } = useCompany()
  const { account } = useAccount(data?.accountId)
  const { update } = useReconcile({ id: data.id })
  const { isAllowed } = useRole(RoleType.Member)

  const matcherRef = useRef<IMatcher>()
  matcherRef.current = matcher

  const qbo = company?.connections.find((c) => c.type.id === ConnectionType.QBO)
  const doneUrl = getDoneUrl(account?.platformId, qbo)

  // TODO: check changes
  // const isChanged = JSON.stringify(data.temp_data) !== JSON.stringify({ qbo: matcher.qbo, bank: matcher.bank })

  const [loading, setLoading] = useState(false)
  const [block, setBlock] = useState(true)
  usePrompt('Results are unsaved. Are you sure you want to leave?', block)

  const clearedQbo = matcher.qbo.filter((v) => v.matchedTo?.length || v.forceMatch)
  const selectedBank = matcher.bank.filter((v) => v.selected && !v.matchedTo?.length)
  const diffArr = [...clearedQbo, ...selectedBank]

  const paymentsAmount = diffArr.filter((v) => v.amount <= 0).reduce((prev, v) => prev + v.amount, 0)
  const depositsAmount = diffArr.filter((v) => v.amount > 0).reduce((prev, v) => prev + v.amount, 0)

  const cleared = data.starting_balance - Math.abs(paymentsAmount) + depositsAmount
  const delta = data.ending_balance - cleared
  const bankUnmatched = matcher.bank.filter((v) => !v.matchedTo.length)
  const adaptedDelta = parseFloat(delta.toFixed(2))
  const doneReady = !bankUnmatched.length && !adaptedDelta

  if (!data) return null

  const { from, to } = data

  const onSave = () => {
    const _qbo = matcherRef.current?.qbo || matcher.qbo || []
    const _bank = matcherRef.current?.bank || matcher.bank || []

    const payload = {
      ...data,
      result: [],
      temp_data: { qbo: _qbo, bank: _bank },
      companyId: (data as any).company.id,
    }

    setLoading(true)
    return update(payload)
      .then((r) => {
        setBlock(false)
        window.onbeforeunload = null
        notification.success({ message: 'Reconciliation saved' })
        setTimeout(() => navigate('../history'), 100)
        return r
      })
      .catch((e) => notification.error(getErrorMsg(e)))
      .finally(() => setLoading(false))
  }

  const onDone = () => {
    onSave().then((payload: any) => {
      if (!payload) return
      console.log('onDone payload', payload)
      sendDataToPlugin({ ...payload, cache: true } as IReconcile)
      window.open(doneUrl, '_blank')?.focus()
    })
  }

  return (
    <Card style={{ height: 180 }} size="small">
      <Flex vertical gap={5}>
        <Text>
          <Text type="secondary">Account: </Text>
          <Text strong>{account?.name}</Text>
        </Text>
        <Text style={textStyle}>
          <Text type="secondary">Account type: </Text>
          <Text strong>{account?.type}</Text>
        </Text>
        <Text style={textStyle}>
          <Text type="secondary">Date range: </Text>
          <Text strong>{formatDate(from, 'll')}</Text>
          <Text> - </Text>
          <Text strong>{formatDate(to, 'll')}</Text>
        </Text>
        <Text>
          <Text type="secondary">Auto matching: </Text>
          <Text strong>{data.dayrange}</Text>
          <Text> day(s)</Text>
        </Text>
        <Flex gap={10} style={{ marginTop: 10 }}>
          <Button size="small" type="primary" onClick={onDone} disabled={!doneReady || loading || !isAllowed}>
            Done
          </Button>
          <Button size="small" onClick={onSave} disabled={loading || !isAllowed}>
            Save for later
          </Button>
        </Flex>
      </Flex>
    </Card>
  )
}

function sendDataToPlugin(rec: IReconcile) {
  const data = { type: 'FROM_PAGE_REC', rec }

  window.postMessage(data, '*')
}

function getDoneUrl(accountId?: string, connection?: ICompanyConnection) {
  if (!connection || !accountId) return ''

  const isPlugin = isPluginInstalled()

  const connectionInfo = connection.connectionInfo
  const { realm_id } = connectionInfo

  if (!isPlugin)
    return `https://c1.qbo.intuit.com/qbo1/login?pagereq=switchCompany%3FcompanyId%3D${realm_id}%26navigationURL%3Dreconcile`

  return `https://c1.qbo.intuit.com/qbo1/login?pagereq=switchCompany%3FcompanyId%3D${realm_id}%26navigationURL%3DreconcileAccount*accountId=${accountId}`
}

const textStyle: React.CSSProperties = {}
