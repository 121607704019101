import React, { useCallback, useState } from 'react'
import { Modal, Flex, Typography, Input } from 'antd';

const { Text } = Typography;

interface ShopifyConnectModalProps {
  visible: boolean;
  onCancel: () => void;
  onConnect: (storeUrl: string) => void;
  loading: boolean;
}

const ShopifyConnectModal: React.FC<ShopifyConnectModalProps> = ({ visible, onCancel, onConnect, loading }) => {
  const [storeUrl, setStoreUrl] = useState<string>('');

  const cleanShopifyUrl = useCallback((url: string): string => {
    // Remove http:// or https:// if present
    let cleanedUrl = url.replace(/^(https?:\/\/)/, '');

    // Remove www. if present
    cleanedUrl = cleanedUrl.replace(/^www\./, '');

    // Ensure the URL ends with .myshopify.com
    if (!cleanedUrl.endsWith('.myshopify.com')) {
      cleanedUrl = cleanedUrl.split('.')[0] + '.myshopify.com';
    }

    return cleanedUrl;
  }, []);

  const handleConnect = () => {
    onConnect(storeUrl);
    // Don't reset the input here, as it might be confusing if the connection fails
    // The parent component should handle resetting the modal state
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const cleanedValue = cleanShopifyUrl(inputValue);
    setStoreUrl(cleanedValue);
  };

  return (
    <Modal
      title="Connect to Shopify"
      visible={visible}
      onCancel={onCancel}
      onOk={handleConnect}
      okButtonProps={{ disabled: !storeUrl.trim() || loading, loading: loading }}
      cancelButtonProps={{ disabled: loading }}
      closable={!loading}
      maskClosable={!loading}
    >
      <Flex vertical gap={10}>
        <Text>Enter your Shopify store URL:</Text>
        <Input
          placeholder="yourstorename.myshopify.com"
          value={storeUrl}
          onChange={handleInputChange}
          disabled={loading}
        />
      </Flex>
    </Modal>
  );
};

export default ShopifyConnectModal;