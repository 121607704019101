export interface ITx {
  id: string | number
  _id: string
  amount: number
  date: string
  customer?: string
  vendor?: string

  matchedTo: ITx[]
  forceMatch?: boolean
  potentialMatches?: ITx[]

  selected?: boolean
  deleted?: boolean

  memo: string
  description?: string // depricated
  account?: string
  type?: any
}

export enum TRANSACTION_TYPES {
  DEPOSIT = 'DEPOSIT',
  EXPENSE = 'EXPENSE',
  CC = 'CREDIT_CARD_CREDIT',
  JE = 'JOURNAL_ENTRY',
  BILL = 'BILL_PAYMENT',
  TRANSFER = 'TRANSFER',
}

export interface CsvBoxTx {
  Date: string
  Description: string
  Amount?: string
  Spent?: string
  Received?: string
  Expence?: string
  Payment?: string
}
