import useSWR, { useSWRConfig } from 'swr'

import type { ICompany } from 'types/company.types'

export function useCompanies() {
  const { mutate: revalidate } = useSWRConfig()

  const companies = useSWR<ICompany[]>('companies')

  let data = companies.data
  data = companies.data
    ?.filter((company) => company.name !== 'Draft')
    .sort((a, b) => {
      const name = a.alias || a.name
      return name.localeCompare(b.alias || b.name)
    })

  return {
    ...companies,
    data,
    revalidate: () => revalidate(`companies`),
  }
}

export function useLastCompaniesReviews() {
  const { mutate: revalidate } = useSWRConfig()

  const last = useSWR<ILastReview[]>('review/last')

  return {
    ...last,
    revalidate: () => revalidate(`review/last`),
  }
}

interface ILastReview {
  id: number
  company: {
    id: number
  }
  createdAt: string
  updatedAt: string
  rating: number
}
