export interface IConnectionItem {
  image: string
  title: string
  disabled?: boolean
  connected?: boolean
  status?: string
}

export enum ConnectionAction {
  Connect,
  Setup,
}
