import useSWR from 'swr'

import { api } from 'utils/axios'
import type { IReport } from 'types/report.type'

interface IParams {
  companyId: number
  accountId: string
  from: string
  to: string
  method?: string
}

export function useTransactions(params: IParams) {
  const key =
    params.companyId && params.accountId && params.from && params.to ? ['/accounting/transactions', params] : null

  return useSWR<IReport>(key, () => fetch(params))
}

const fetch = (params: IParams) => {
  if (!params.companyId) return Promise.resolve([])
  if (!params.accountId) return Promise.resolve([])
  if (!params.from) return Promise.resolve([])
  if (!params.to) return Promise.resolve([])

  return api.get('/accounting/transactions', { params }).then((res) => res.data)
}
