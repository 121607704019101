import { useUser } from './useUser'

export function useAccessLevel(min: AccessLevel = 0) {
  const { user, isLoading, error } = useUser()

  const accessLevel = user?.accessLevel || 0

  return {
    accessLevel,
    isAllowed: accessLevel >= min,
    isLoading,
    error,
  }
}

export enum AccessLevel {
  FREE = 0,
  PRO = 1,
  ADVANCED = 2,
}
