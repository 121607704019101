
const env = process.env.REACT_APP_ENV || 'local'

const isLocal = env === 'local'
const isDev = env === 'development'
const isProd = env === 'production'

const measurmentId = isProd ? 'G-7WECYNSF11' : 'G-YBT7XVW90P'


const firebaseConfig = isProd ? {
  apiKey: 'AIzaSyBmXnX9sCClkAN6Ypa-gShS9Vnq6J2Aqbg',
  authDomain: 'lanyap.firebaseapp.com',
  databaseURL: 'https://lanyap.firebaseio.com',
  projectId: 'lanyap',
  storageBucket: 'lanyap.appspot.com',
  messagingSenderId: '234879083124',
  appId: '1:234879083124:web:f27af35f1d9dbc99ed6721',
  measurementId: 'G-43NEFDN9HX'
} : {
  apiKey: "AIzaSyCwLSOPK-cRVk6iZsNT1xSOk7XblemmNCU",
  authDomain: "equility-dev-436920.firebaseapp.com",
  projectId: "equility-dev-436920",
  storageBucket: "equility-dev-436920.firebasestorage.app",
  messagingSenderId: "753594754871",
  appId: "1:753594754871:web:987175eaaa36c5366c1cb2"
}

export const config = {
  env,
  isDev,
  isProd,
  isLocal,
  prefix: 'equilityhq_', // prefix for local storage
  timeout: {
    logout: 29 * 60 * 1000 // millisecs
  },
  api: {
    baseURL: process.env.REACT_APP_API_BASE_URL || 'https://api-dev.equilityhq.com/api/v2/'
  },
  firebase: firebaseConfig,
  stripe: {
    apiKey: process.env.REACT_APP_STRIPE_API_KEY || '',
    timeout: 5000
  },
  googleClient: {
    id: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
  },
  ga: {
    id: measurmentId
  },
  csvbox: {
    licenseKey: 'hEKT8TOuof8YtPhzNVFsrgqUnZhN38',
    useStagingServer: false
  },
  sentry: {
    dsn: 'https://6afeaa4aca024818a4ee50bb4e0480a1@o914539.ingest.sentry.io/6100316'
  },
  links: {
    privacy: 'https://equilityhq.com/privacy-policy/',
    terms: 'https://equilityhq.com/terms-and-conditions/',
    qbo: 'https://app.qbo.intuit.com/app/',
    downloadPlugin: 'https://chrome.google.com/webstore/detail/lanyap-extension/idnaidbjgchinhlfgjdpjmkgmblaemgi?hl=en&authuser=3'
  },
}
