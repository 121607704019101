import { useLayoutEffect, useRef, useState } from 'react'
import { Button, Divider, Flex, Segmented, Table, TableColumnsType, Typography, notification } from 'antd'
import { CheckCircleTwoTone, MoreOutlined, PlusCircleTwoTone } from '@ant-design/icons'

import { ColorNumber } from 'components/ColorNumber'
import { AddToQBO } from './AddToQBO'
import { MatchModal } from './Match'

import { api } from 'utils/axios'
import { reportToTsx } from 'utils/tx'
import { getErrorMsg } from 'utils/geterror'
import { prevMonthStart } from 'utils/dates'
import { useRole } from 'hooks/useRole'
import { RoleType } from 'types/user.types'
import type { IReconcile } from 'types/reconcile'
import type { IMatcher } from 'types/matcher'
import type { ITx } from 'types/tx.types'

interface Props {
  type: 'qbo' | 'bank'
  title: string
  matcher: IMatcher
  rec: IReconcile
  loading: boolean
  onLoading: (loading: boolean) => void
}

const { Text } = Typography

export function TxList(props: Props) {
  const { type, matcher, title, rec } = props
  const { loading, onLoading } = props

  const { isAllowed } = useRole(RoleType.Member)

  const data = matcher[type] || []
  const btnRef = useRef<any>()

  const ref = useRef<HTMLDivElement>(null)
  const height = useWindowHeight()
  const top = ref?.current?.getBoundingClientRect().top || 0
  const minHeight = height - top - 215

  const cleared = data.filter((v) => v.matchedTo?.length || v.forceMatch)
  const selected = data.filter((v) => v.selected)

  const [filter, setFilter] = useState<FilterTx>('Unmatched')
  const [uploadModal, setUploadModal] = useState(false)
  const [matchModal, setMatchModal] = useState(false)
  const [selectedTx, setSetSelectedTx] = useState<ITx[]>([])

  let dataSource =
    filter === 'Matched'
      ? data.filter((tx) => tx.matchedTo?.length || tx.forceMatch)
      : filter === 'Unmatched'
        ? data.filter((tx) => !tx.matchedTo?.length && !tx.forceMatch)
        : data
  dataSource = dataSource.filter((v) => !v.deleted)

  const select = type === 'qbo' ? matcher.selectQbo : matcher.selectBank

  columns[3].onCell = (record: ITx) => ({
    onClick: () => {
      if (!isAllowed) return
      setSetSelectedTx([record])
      setMatchModal(true)
    },
  })

  const matchSelected = () => {
    setSetSelectedTx(dataSource.filter((v) => v.selected))
    setMatchModal(true)
  }

  const onDelete = () => {
    matcher.markAsDeleted(selected.map((v) => v._id))
    setSetSelectedTx([])
    console.log(selected.map((v) => v._id))
    console.log('onDelete', matcher.qbo)
  }

  const onRematch = () => {
    setTimeout(() => {
      btnRef?.current?.click() // TODO: Fix this
    }, 250)
  }

  const onRefresh = () => {
    const companyId = rec.companyId || (rec as any).company.id
    onLoading(true)
    return refreshTxs(companyId, rec.accountId, rec.from, rec.to)
      .then((txs) => matcher.refreshQbo(txs))
      .catch((e) => notification.error(getErrorMsg(e)))
      .finally(() => onLoading(false))
  }

  const onAddTx = () => {
    matcher.selectBank([])
    onRefresh().then(() => onRematch())
  }

  return (
    <Flex vertical ref={ref}>
      <Divider orientation="left">
        <Text>{title}</Text> <Text type="secondary">{data.length} total</Text>
        <Text type="secondary"> / {cleared.length} matched</Text>
        {!!selected.length && <Text type="secondary"> / {selected.length} selected </Text>}
      </Divider>
      <Flex gap={10} vertical>
        <Flex gap={10} justify="space-between">
          <Segmented
            size="small"
            options={['Matched', 'Unmatched', 'All']}
            value={filter}
            onChange={(v: any) => setFilter(v)}
          />
          {type === 'bank' && !!selected.length && isAllowed && (
            <Flex gap={5}>
              <Button type="link" size="small" onClick={() => setUploadModal(true)}>
                Add to Quickbooks
              </Button>
              <Button type="link" size="small" onClick={() => matchSelected()}>
                Match
              </Button>
            </Flex>
          )}
          {type === 'bank' && !selected.length && isAllowed && (
            <Flex gap={5}>
              <Button type="text" size="small" onClick={() => matcher.unmatchAll()}>
                Unmatch all
              </Button>
              <Button type="text" size="small" onClick={() => matcher.matchAll()} ref={btnRef}>
                Rematch
              </Button>
            </Flex>
          )}
          {type === 'qbo' && !!selected.length && isAllowed && (
            <Button type="link" size="small" danger onClick={() => onDelete()}>
              Delete
            </Button>
          )}
          {type === 'qbo' && !selected.length && isAllowed && (
            <Button size="small" type="text" onClick={() => onRefresh()}>
              Refresh
            </Button>
          )}
        </Flex>
        <Table
          virtual
          rowKey={'_id'}
          size="small"
          columns={columns}
          dataSource={dataSource}
          scroll={{ y: minHeight }}
          style={tableStyle}
          pagination={false}
          loading={loading}
          rowSelection={{
            type: 'checkbox',
            columnWidth: 40,
            selectedRowKeys: selected.map((v) => v._id),
            onChange: (ids) => select(ids as string[]),
            getCheckboxProps: (tx: ITx) => ({ disabled: isMatched(tx) }),
          }}
        />
      </Flex>
      <AddToQBO
        open={uploadModal}
        onClose={(isOk) => {
          if (isOk) onAddTx()
          setUploadModal(false)
        }}
        accountId={rec.accountId}
        list={dataSource.filter((v) => v.selected)}
      />
      <MatchModal
        type={type}
        open={matchModal}
        list={selectedTx}
        matcher={matcher}
        onClose={() => setMatchModal(false)}
      />
    </Flex>
  )
}

const columns: TableColumnsType<ITx> = [
  {
    title: 'Date',
    dataIndex: 'date',
    width: 110,
    sorter: (a: ITx, b: ITx) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    width: 130,
    render: (value: number) => <ColorNumber amount={value} />,
    sorter: (a: ITx, b: ITx) => a.amount - b.amount,
  },
  {
    title: 'Memo',
    dataIndex: 'memo',
    ellipsis: true,
    sorter: (a: ITx, b: ITx) => a.memo.localeCompare(b.memo),
  },
  {
    title: 'Match',
    dataIndex: '_id',
    width: 70,
    render: (_, record) => {
      const cheked = !!record.forceMatch || !!record.matchedTo.length
      return (
        <Flex gap={10}>
          {cheked ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <PlusCircleTwoTone twoToneColor="#CCC" />}
          <Button size="small" type="text" icon={<MoreOutlined />} style={moreBtnStyle} />
        </Flex>
      )
    },
  },
]

const isMatched = (tx: ITx): boolean => tx.matchedTo.length > 0 || !!tx.forceMatch

function refreshTxs(companyId: number, accountId: string, from: string, to: string) {
  const before = prevMonthStart(from)
  const params = { accountId, companyId, from: before, to }
  return api
    .get('/accounting/transactions', { params })
    .then((r) => r.data)
    .then((r) => reportToTsx(r, accountId, true))
}

function useWindowHeight() {
  const [size, setSize] = useState(0)
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerHeight)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

type FilterTx = 'Matched' | 'Unmatched' | 'All'

const tableStyle: React.CSSProperties = {
  borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
}

const moreBtnStyle: React.CSSProperties = {
  marginBottom: -5,
  marginTop: -5,
}
