import { Alert, Button, Col, Divider, Flex, Modal, Row, Typography, notification } from 'antd'

import { MatchAccounts } from 'components/MatchAccounts'
import { CardIntegration } from 'components/CardIntegration'

import { useAccounts } from 'hooks/useAccounts'
import { useCompany } from 'hooks/useCompany'

import { useRole } from 'hooks/useRole'
import { api } from 'utils/axios'
import { connectQBO } from 'utils/qbolink'
import { getErrorMsg } from 'utils/geterror'
import { ConnectionType } from 'types/company.types'
import { RoleType } from 'types/user.types'

import qboLogo from 'assets/quickbooks.png'
import { useFlags } from 'flagsmith/react'

const { Text } = Typography

export function Accounting() {
  const { accounts, isLoading, revalidate: revalidateAccounts } = useAccounts()
  const { company, update, revalidate } = useCompany()
  const { isAllowed } = useRole(RoleType.Member)

  const flags = useFlags(['disable_chrome_plugin'])

  const uncat = company?.settings?.uncat?.selected || []

  const qbo = (company?.connections || []).find((i) => i.type.id === ConnectionType.QBO)
  const isConnected = qbo?.status === 1

  const onUncatChange = (value: any) => {
    const v = { selected: value?.uncat || [] }
    const settings = { ...company?.settings, uncat: v }
    update({ ...company, settings })
  }

  const onRevoke = () => {
    const url = `/companies/${company?.id}/connection/${qbo?.keyId}/revoke`
    api
      .get(url)
      .then(() =>
        notification.warning({
          message: 'Connection revoked',
          description: 'You have successfully revoked access to QuickBooks Online',
        }),
      )
      .catch((err) => notification.error(getErrorMsg(err)))
      .finally(() => revalidate())
  }

  const onConnect = async () => {
    if (!company) return

    connectQBO(company.id)
      .then(() =>
        notification.success({
          message: 'Connected',
          description: 'You have successfully connected to QuickBooks Online',
        }),
      )
      .catch((err) => notification.error(getErrorMsg(err)))
      .finally(() => {
        revalidate()
        revalidateAccounts()
      })
  }

  const onClick = () => {
    if (!isAllowed)
      return notification.error({ message: 'Error', description: 'You are not allowed to perform this action' })
    if (!isConnected) return onConnect()

    Modal.info({
      title: 'Quickbooks Integration',
      content: (
        <Flex vertical gap={10}>
          <Text>Revoke access to QuickBooks Online</Text>
          <Flex>
            <Button danger onClick={onRevoke}>
              Revoke
            </Button>
          </Flex>
        </Flex>
      ),
      onOk() {},
    })
  }

  return (
    <Flex vertical>
      {!isConnected &&  !flags?.disable_chrome_plugin?.enabled && (
        <Alert
          message="Quickbooks Integration"
          description="Connect your Accounting platform to use the features"
          type="warning"
          showIcon
        />
      )}
      <Divider orientation="left">Platform</Divider>
      <Row gutter={16}>
        <Col span={6}>
          <CardIntegration
            onClick={onClick}
            item={{ image: qboLogo, title: 'Quickbooks', disabled: flags?.disable_chrome_plugin?.enabled, connected: isConnected }}
          />
        </Col>
      </Row>
      {company?.name && (
        <>
          <Divider orientation="left">Integration</Divider>
          <Text>{company?.name} (QuickBooks Online)</Text>
        </>
      )}
      <Divider orientation="left">Settings</Divider>
      <Flex vertical gap={10}>
        <Text>Check for Uncategorized Transactions in the GL account(s):</Text>
        <MatchAccounts
          value={{ uncat }}
          onChange={onUncatChange}
          accounts={accounts}
          loading={isLoading}
          options={[{ id: 'uncat', name: 'Account(s)' }]}
          placeholder="Select Account(s)"
          multiple
          hidelabel
          disabled={!isAllowed}
        />
      </Flex>
    </Flex>
  )
}
