const numberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export function toNumber(value: number | string) {
  const v = getNumber(value)
  return numberFormatter.format(v)
}

export function toCurrency(value: number, parenthesis = true) {
  if (value < 0) {
    const v = currencyFormatter.format(value).replace('-', '')
    if (v === '$0.00') return v
    if (parenthesis) return '(' + v + ')'
    else return v
  }
  return currencyFormatter.format(value || 0)
}

export function toPercentage(value: number) {
  let v = value
  if (v === Infinity) v = 100
  if (v === -Infinity) v = -100
  return numberFormatter.format(v) + '%'
}

function getNumber(value: number | string) {
  return typeof value === 'string' ? parseFloat(value) || 0 : value || 0
}

export function toSafeDecimal(value: number | string) {
  return typeof value === 'string' ? parseFloat(parseFloat(value).toFixed(2)) : parseFloat(value.toFixed(2))
}
