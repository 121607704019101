import useSWR from 'swr'

import { useCompany } from './useCompany'
import { ConnectionType } from 'types/company.types'
import type { IPlaidLinkToken } from 'types/user.types'

export function usePlaidLinkToken() {
  const { data, error, isLoading } = useSWR<IPlaidLinkToken>('user/plaid', { revalidateOnFocus: false })

  return { token: data, error, isLoading }
}

export function usePlaidAccounts() {
  const { company } = useCompany()

  const connections = company?.connections

  const plaid = connections?.find((c) => c.type.id === ConnectionType.Plaid)

  console.log('plaid', plaid)

  return { plaid }
}
