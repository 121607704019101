import { Flex, Radio, Typography } from 'antd'

import { useCompany } from 'hooks/useCompany'
import { ConnectionType } from 'types/company.types'

const { Text } = Typography

interface Props {
  onSelect: (id: string) => void
}

export function PlaidList(props: Props) {
  const { onSelect } = props
  const { company } = useCompany()

  const plaidItems: IPlaidItem[] = (company?.connections || [])
    .filter((i) => i.type.id === ConnectionType.Plaid)
    .map(v => v.connectionInfo)

  if (!plaidItems.length) return <Text type="secondary">No Plaid accounts connected</Text>

  return (
    <Radio.Group style={{ width: '100%' }} onChange={(e) => onSelect(e.target.value)}>
      <Flex vertical gap={10} style={listStyle}>
        {plaidItems.map((m, i) => (
          <Flex vertical gap={10} key={i}>
            <Text strong>{m.institution.name}</Text>
            {m.accounts.map((acc) => (
              <Radio value={acc.account_id} key={acc.account_id}>
                <Text>{acc.name}</Text>
                <Text type="secondary">
                  <small> ****{acc.mask}</small>
                </Text>
              </Radio>
            ))}
          </Flex>
        ))}
      </Flex>
    </Radio.Group>
  )
}

interface IPlaidItem {
  item_id: string
  institution: any
  accounts: any[]
}

const listStyle: React.CSSProperties = {
  maxHeight: 240,
  overflowY: 'auto',
}
