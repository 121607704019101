import { RoleType } from 'types/user.types'
import { useUser } from './useUser'

export function useRole(min: RoleType) {
  const { user, isLoading, error } = useUser()

  const teamRole = user?.member?.role ?? 10000
  const isOwner = user?.id === user?.member?.team?.createdBy
  const role = isOwner ? -1 : teamRole

  return {
    role,
    isAllowed: role <= min || teamRole===RoleType.Admin,
    isLoading,
    error,
  }
}
