import { Select } from 'antd'

interface Props extends React.ComponentProps<typeof Select> {}

export function SelectEntity(props: Props) {
  return <Select options={companyEntities} {...props} placeholder="Select legal entity type" />
}

export const companyEntities = [
  {
    value: 1,
    label: 'Sole Proprietor / Pass-through LLC (Form 1040)',
  },
  {
    value: 2,
    label: 'Partnership (Form 1065)',
  },
  {
    value: 3,
    label: 'Small business corporation, one or more owners (Form 1120S)',
  },
  {
    value: 4,
    label: 'Corporation, one or more shareholders (Form 1120)',
  },
  {
    value: 5,
    label: 'Nonprofit Organization (Form 990)',
  },
]

export function getEntityLabel(value: number) {
  return companyEntities.find((o) => o.value === value)?.label
}
