import { useEffect, useState } from 'react'
import { AutoComplete, Button, Modal, Select, Table, notification, Space, Tooltip, Card } from 'antd'
import { Copy, CopyCheck } from 'lucide-react'
import { ColorNumber } from 'components/ColorNumber'

import { useAccounts } from 'hooks/useAccounts'
import { useCompany } from 'hooks/useCompany'
import { useVendors } from 'hooks/useVendors'
import { api } from 'utils/axios'
import { formatDate } from 'utils/dates'
import type { IAccount } from 'types/account.types'
import type { IQboCustomer } from 'types/company.types'
import { TRANSACTION_TYPES, type ITx } from 'types/tx.types'

interface Props {
  accountId: string
  list: ITx[]
  open: boolean
  onClose: (refresh?: boolean) => void
}

export function AddToQBO(props: Props) {
  const { accountId, list, open, onClose } = props

  const { accounts } = useAccounts()
  const { company } = useCompany()

  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState<any>({})
  const [copiedAccount, setCopiedAccount] = useState<string>()
  const [copiedName, setCopiedName] = useState<string>()
  const [bulkMode, setBulkMode] = useState(false)
  const [bulkAccount, setBulkAccount] = useState<string>()
  const [bulkName, setBulkName] = useState<number>()

  const account = accounts.find((v) => v.platformId === accountId)
  const typeOptions = getTypeOptions(!!account?.isLiability, !!account?.isBank)

  const { customers, vendors } = useVendors()
  const agents = [...customers, ...vendors]

  useEffect(() => {
    // console.log(1231313123131313, 'useEffect')
    const newMeta = {} as any
    list.forEach((tx) => {
      if (!!account?.isBank)
        newMeta[tx._id] = account.isLiability ? { type: tx.amount >= 0 ? 0 : 1 } : { type: tx.amount >= 0 ? 1 : 0 }
      else {
        newMeta[tx._id] = { type: 0 }
      }
    })
    setMeta(newMeta)
    setLoading(false)
    // Reset bulk mode when modal reopens
    setBulkMode(false)
    setBulkAccount(undefined)
    setBulkName(undefined)
  }, [list, open])

  if (!account) return null

  // console.log('Meta', meta)

  const handleUpdateTx = (tx: ITx, txmeta: any) => {
    const newMeta = Object.assign({}, meta)
    newMeta[tx._id] = newMeta[tx._id] || {}
    newMeta[tx._id] = { ...newMeta[tx._id], ...txmeta }
    // console.log('New Meta', newMeta)
    setMeta(newMeta)
  }

  const handleBulkApply = () => {
    const newMeta = { ...meta }
    list.forEach((tx) => {
      newMeta[tx._id] = {
        ...(newMeta[tx._id] || {}),
        ...(bulkAccount ? { accountId: bulkAccount } : {}),
        ...(bulkName ? { name: bulkName } : {}),
      }
    })
    setMeta(newMeta)
    notification.success({
      message: 'Bulk Update Applied',
      description: 'All transactions have been updated with the selected values',
    })
  }

  const handleSubmit = () => {
    const transactions = list.map((tx) => prepareTx(tx, meta, account, typeOptions, customers))

    if (transactions.find((v) => !v.accountId)) {
      console.info(
        'Missing account',
        transactions.find((v) => !v.accountId),
      )
      return notification.warning({
        message: 'Missing account',
        description: 'Please select an account for all transactions',
      })
    }

    // return console.log('Submit', meta)

    setLoading(true)

    api
      .post('/accounting/transactions', { transactions, companyId: company?.id })
      .then(() => {
        notification.info({ message: 'Success!', description: 'Transaction(s) added to QuickBooks' })
        onClose(true)
      })
      .catch(() => notification.error({ message: 'Error adding transaction(s) to QuickBooks' }))
      .finally(() => setLoading(false))
  }
  const tableColumns = [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      width: 130,
      render: (date: string) => formatDate(date),
    },
    {
      title: 'Account',
      key: 'account',
      width: 310,
      render: (_: any, tx: ITx) => {
        const currentAccountId = meta[tx._id]?.accountId
        const value = accounts.find((v) => v.platformId === currentAccountId)?.name
        const isEditing = value && currentAccountId !== copiedAccount

        return (
          <Space>
            <CustomSelect
              value={value}
              options={accounts.map((v) => ({ key: v.platformId, value: v.name }))}
              onChange={(v) => handleUpdateTx(tx, { accountId: v })}
            />
            <Tooltip title={isEditing ? 'Copy account' : copiedAccount ? 'Paste account' : 'Copy account'}>
              <Button
                size="small"
                type={currentAccountId === copiedAccount ? 'primary' : 'default'}
                onClick={() => {
                  if (isEditing) {
                    // Only copy if we're editing
                    setCopiedAccount(currentAccountId)
                    notification.info({ message: 'Account copied' })
                  } else if (copiedAccount) {
                    // Paste if we have a copied value
                    handleUpdateTx(tx, { accountId: copiedAccount })
                  } else {
                    // Copy if we have a value but no copied value
                    setCopiedAccount(currentAccountId)
                    notification.info({ message: 'Account copied' })
                  }
                }}
              >
                {isEditing ? (
                  <Copy className="w-4 h-4" />
                ) : currentAccountId === copiedAccount ? (
                  <CopyCheck className="w-4 h-4" />
                ) : (
                  <Copy className="w-4 h-4" />
                )}
              </Button>
            </Tooltip>
          </Space>
        )
      },
    },

    {
      title: 'Name',
      key: 'name',
      width: 310,
      render: (_: any, tx: ITx) => {
        const currentNameId = meta[tx._id]?.name
        const value = agents.find((v) => v.Id === currentNameId)?.DisplayName
        const isEditing = value && currentNameId !== copiedName

        return (
          <Space>
            <CustomSelect
              value={value}
              options={agents.map((v) => ({ key: v.Id, value: v.DisplayName }))}
              onChange={(v) => handleUpdateTx(tx, { name: v })}
            />
            <Tooltip title={isEditing ? 'Copy name' : copiedName ? 'Paste name' : 'Copy name'}>
              <Button
                size="small"
                type={currentNameId === copiedName ? 'primary' : 'default'}
                onClick={() => {
                  if (isEditing) {
                    // Only copy if we're editing
                    setCopiedName(currentNameId)
                    notification.info({ message: 'Name copied' })
                  } else if (copiedName) {
                    // Paste if we have a copied value
                    handleUpdateTx(tx, { name: copiedName })
                  } else {
                    // Copy if we have a value but no copied value
                    setCopiedName(currentNameId)
                    notification.info({ message: 'Name copied' })
                  }
                }}
              >
                {isEditing ? (
                  <Copy className="w-4 h-4" />
                ) : currentNameId === copiedName ? (
                  <CopyCheck className="w-4 h-4" />
                ) : (
                  <Copy className="w-4 h-4" />
                )}
              </Button>
            </Tooltip>
          </Space>
        )
      },
    },
    {
      title: 'Memo',
      key: 'description',
      dataIndex: 'description',
      width: 200, // Add fixed width
      render: (text: string) => (
        <div
          style={{
            position: 'relative',
            zIndex: 0, // Lower z-index
            background: 'white',
            padding: '8px 12px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: 'Type',
      key: 'type',
      width: 210,
      render: (_: any, tx: ITx) => (
        <Select
          style={{ width: 200 }}
          options={typeOptions}
          value={meta[tx._id]?.type}
          onChange={(v) => handleUpdateTx(tx, { type: v })}
        />
      ),
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount', // Add this
      width: 140,
      render: (_: any, tx: ITx) => <ColorNumber amount={tx.amount} />, // Use tx.amount directly instead of v
    },
  ]

  if (!open || !account) return null

  return (
    <Modal
      width={1300}
      title="Export To QuickBooks"
      open={open}
      onCancel={() => onClose()}
      footer={[
        <Button onClick={() => onClose()} key="cancel">
          Cancel
        </Button>,
        <Button onClick={handleSubmit} type="primary" key="submit" loading={loading}>
          Upload
        </Button>,
      ]}
    >
      {bulkMode ? (
        <Card style={{ padding: '1px' }}>
          <h3 className="m-0 text-lg font-medium">Bulk Edit Mode</h3>
          <Space className="w-full" size="middle">
            <CustomSelect<string>
              value={accounts.find((v) => v.platformId === bulkAccount)?.name}
              options={accounts.map((v) => ({ key: v.platformId, value: v.name }))}
              onChange={setBulkAccount}
              placeholder="Select account for all transactions"
            />
            <CustomSelect<number>
              value={agents.find((v) => v.Id === bulkName)?.DisplayName}
              options={agents.map((v) => ({ key: v.Id, value: v.DisplayName }))}
              onChange={setBulkName}
              placeholder="Select name for all transactions"
            />
            <Space className="w-full justify-between items-center">
              <Button type="primary" onClick={handleBulkApply}>
                Apply to All Transactions
              </Button>
            </Space>
          </Space>
        </Card>
      ) : (
        <Button onClick={() => setBulkMode(true)}>Enable Bulk Edit</Button>
      )}

      <Table
        size="small"
        className="Table"
        rowKey="_id"
        dataSource={list}
        columns={tableColumns}
        pagination={false}
        scroll={{ y: bulkMode ? 350 : 400 }}
      />
    </Modal>
  )
}

interface CustomSelectProps<T> {
  options: Array<{ key: T; value: string }>
  value?: string
  onChange: (value: T | undefined) => void
  placeholder?: string
}

const CustomSelect = <T extends string | number>({ options, value, onChange, placeholder }: CustomSelectProps<T>) => {
  const [val, setVal] = useState<string | undefined>(value)

  useEffect(() => {
    setVal(value)
  }, [value])

  const status = val && !options.some((v) => v.value === val) ? 'error' : ''

  return (
    <AutoComplete
      showSearch
      allowClear
      placeholder={placeholder || 'Select a name'}
      disabled={!options.length}
      style={{ width: '100%', minWidth: '200px' }} // Add minWidth
      className="ant-select-fixed-width" // Add a class for consistency
      options={options}
      filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
      value={val}
      status={status}
      onChange={(value, option) => {
        const selectedOption = Array.isArray(option) ? option[0] : option
        // Update both the display value and trigger the onChange
        setVal(selectedOption?.value || value)
        if (selectedOption?.key) {
          onChange(selectedOption.key as T)
        } else {
          onChange(undefined)
        }
      }}
      onBlur={() => {
        if (status === 'error') setVal(undefined)
      }}
    />
  )
}

function prepareTx(tx: ITx, meta: any, account: IAccount, typeOptions: any[], customers: IQboCustomer[]) {
  const type = typeOptions[meta[tx._id].type]
  const isCustomer = customers.find((v) => v.Id === meta[tx._id].name)
  return {
    accountId: meta[tx._id]?.accountId,
    amount: tx.amount,
    description: tx.description || tx.memo,
    type: type.label,
    txnDate: formatDate(tx.date),
    fundingAccountId: account.platformId + '',
    credit: !!account?.isLiability,
    vendor: !isCustomer ? meta[tx._id].name : undefined,
    customer: isCustomer ? meta[tx._id].name : undefined,
  }
}

function getTypeOptions(isCredit: boolean, isBank: boolean) {
  if (!isBank) return [{ label: TRANSACTION_TYPES.JE, value: 0 }]
  return !isCredit
    ? [
        { label: TRANSACTION_TYPES.EXPENSE, value: 0 },
        { label: TRANSACTION_TYPES.DEPOSIT, value: 1 },
      ]
    : [
        { label: TRANSACTION_TYPES.EXPENSE, value: 0 },
        { label: TRANSACTION_TYPES.CC, value: 1 },
      ]
}
