import { Button, Divider, Flex, Table, TableColumnsType, notification } from 'antd'
import { Link } from 'react-router-dom'

import { ColorNumber } from 'components/ColorNumber'

import { useReview, useReviewsAll } from 'hooks/useReview'
import { formatDateTime } from 'utils/dates'
import { getErrorMsg } from 'utils/geterror'
import { useRole } from 'hooks/useRole'
import { IUser, RoleType } from 'types/user.types'
import type { IReview } from 'types/review.types'

export function ReviewHistory() {
  const list = useReviewsAll()
  const review = useReview({ id: null })
  const { isAllowed } = useRole(RoleType.Admin)

  const isLoading = list.isLoading || review.isLoading

  const onDelete = (id: number) => {
    if (!isAllowed) return
    review
      .delete({ id })
      .then(() => notification.warning({ message: 'Deleted', description: 'Review has been deleted' }))
      .catch((err) => notification.error(getErrorMsg(err)))
  }

  columns[5].render = (id, record) => {
    return (
      <Flex gap={20}>
        <Link to={`../result/${id}`} state={record}>
          <Button size="small" type="text">
            Details
          </Button>
        </Link>
        <Button size="small" type="text" danger onClick={() => onDelete(id)} disabled={!isAllowed}>
          Delete
        </Button>
      </Flex>
    )
  }

  return (
    <Flex vertical gap={10}>
      <Divider orientation="left">Review History</Divider>
      <Table dataSource={list.data} columns={columns} rowKey="id" pagination={false} loading={isLoading} />
    </Flex>
  )
}

const columns: TableColumnsType<IReview> = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: (value: string) => formatDateTime(value, 'lll'),
  },
  {
    title: 'Start Date',
    dataIndex: 'from',
  },
  {
    title: 'End Date',
    dataIndex: 'to',
  },
  {
    title: 'Accuracy',
    dataIndex: 'rating',
    render: (value: number) => <ColorNumber amount={value} min={100} type="percent" />,
  },
  {
    title: 'Completed By',
    dataIndex: 'user',
    render: (user: IUser) => {
      const name = [user?.firstName, user?.lastName].filter(Boolean).join(' ')
      return name || ''
    },
  },
  {
    title: '',
    dataIndex: 'id',
    width: 100,
  },
]
